<template>
  <header class="header">
    <nav class="navbar navbar-default navbar-fixed-top">
      <div class="container-fluid handelbay-container-fluid">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header navbar-header-logo">
          <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-handelbay-tradicionalcollapse-1"
              aria-expanded="false">
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand" href="/" style="display: none;">
            <img src="../assets/images/landing/handelbay/handelbay_logo_menu.png" alt="HandelBay">
          </a>
        </div>

        <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="collapse navbar-collapse handelbay-navbar-collapse" id="bs-handelbay-tradicionalcollapse-1">
          <div class="container without-padding-left">
            <ul class="nav navbar-nav handelbay-navbar-left" @mouseover="hideT()">
              <li>
                <router-link class="logo-handelbay-img" to="/" @click.native="scrollUp">
                  <h1>
                    <img src="../assets/images/landing/handelbay/handelbay_logo_menu.png" alt="HandelBay">
                  </h1>
                </router-link>
              </li>
              <li class="header-list i" v-on:click="classActive('i')" @click="scrollUp">
                <router-link class="navbar-nav_link" to="/">Inicio</router-link>
              </li>
              <li>
                <a class="navbar-nav_link">
                  Empresas
                  <span class="han-sr-only"></span>
                </a>
                <ul class="submenu">
                  <li class="submenu_item" @click="scrollUp">
                    <router-link to="/compradores">
                      Compradores
                      <span class="han-sr-only"></span>
                    </router-link>
                  </li>
                  <li class="submenu_item" @click="scrollUp">
                    <router-link to="/proveedores">
                      Proveedores
                      <span class="han-sr-only"></span>
                    </router-link>
                  </li>
                  <li class="submenu_item" @click="scrollUp">
                    <router-link to="/dual">
                      Dual
                      <span class="han-sr-only"></span>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="header-list t" v-on:click="classActive('t')" @click="scrollUp">
                <router-link class="navbar-nav_link" to="/tarifas">
                  Tarifas
                  <span class="han-sr-only"></span>
                </router-link>
              </li>
              <li class="header-list p" v-on:click="classActive('p')" @click="scrollUp">
                <router-link class="navbar-nav_link" to="/porquehandelbay">
                  ¿Por qué Markley <br> HandelBay?
                  <span class="han-sr-only"></span>
                </router-link>
              </li>
              <li class="header-list a" v-on:click="classActive('a')" @click="scrollUp">
                <router-link class="navbar-nav_link" to="/alianzas">
                  Alianzas
                  <span class="han-sr-only"></span>
                </router-link>
              </li>
            </ul>
            <div class="nav navbar-nav handelbay-navbar-left">
              <select v-model="landing" class="form-control solutions" style="width: 115px" @change="goLanding(landing)">
                <option value="" disabled selected>Soluciones</option>
                <option v-for="item in landings" :value="item.value" :key="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <ul class="nav navbar-nav navbar-right handelbay-navbar-right">
              <div class="img-international" @mouseover="showT()" >
                <img src="../assets/Argentina.png">
                <div style="position:relative;" @mouseover="showT()" @mouseleave="hideT()">
                  <div class="tooltip-country d-none">
                    Estás en HandelBay {{url.name_country}}
                    <a href="https://handelbay.com/">cambia de país aquí</a>
                  </div>
                </div>
              </div>
              <li class="active navbar-right_item" @mouseover="hideT()">
                <a :href="url.sign_up">
                  REGISTRATE GRATIS
                </a>
              </li>
              <li class="navbar-right_item">
                <a :href="url.sign_in">
                  Ingresar
                </a>
              </li>
              <li>
              </li>
            </ul>
          </div>
        </div>
        <!-- /.navbar-collapse -->

        <!-- <a href="/buscar" class="search-icon-landing"> -->
        <a :href="url.search" class="search-icon-landing d-none">
          <i class="icon dripicons-search"></i>
        </a>
      </div>
      <!-- /.container-fluid -->
      </nav>
  </header>
</template>

<script>

import global from '@/components/Global.vue'

export default {
  methods:{
    classActive: (clas) => {
      $('li.header-list.active').removeClass('active');
      $("."+clas).addClass('active');
      $("body").focus();
    },
    scrollUp: () => {
      window.scroll({top:0})
    },
    hideT: () => {
      $(".tooltip-country").addClass("d-none")
    },
    showT: () => {
      $(".tooltip-country").removeClass("d-none")
    },goLanding: (landing) => {
      var win = window.open(landing, '_blank');
      win.focus();
    },
  },
  data(){
    return{
      url: global,
      langs: false,
      landings: [
      {id: 1 ,name: 'Licitaciones privadas', value: "https://lp.handelbay.com/comprador-licitaciones-privadas-0"},
      {id: 2 ,name: 'Portal de proveedores', value: "https://lp.handelbay.com/portal-de-proveedores-0"},
      {id: 3 ,name: 'Requisiciones internas', value: "https://lp.handelbay.com/comprador-requisiciones-internas-0"},
      {id: 4 ,name: 'Gestor de cotizaciones', value: "https://lp.handelbay.com/comprador-gestor-de-cotizaciones-0"},
      {id: 5 , name: 'Evaluación de proveedores', value: "https://lp.handelbay.com/evaluaci%C3%B3n-de-proveedores-0"}
    ],
    landing:""
    }
  }
}
 
</script>
<style lang="scss">
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
  color: #f4f8fb;
  background-color: #e7e7e7;
}
.img-international{
  margin-right: 20px;
  img{
    width: 30px;
    height: 30px;
    border-radius: 25px;
  }
  .tooltip-country{
    position:absolute;
    width: 240px;
    left: -110px;
    top: 5px;
    background: #e7e7e7;
    border-radius: 10px;
    padding: 5px;
    font-size: 14px;
    a{
      font-size: 13px;
      text-decoration: underline;
    }
  }
}
</style>
