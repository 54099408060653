<script>

const url = "https://app.handelbay.com.ar"
const sign_up = url + "/users/sign_up"
const sign_in = url + "/users/sign_in"
const search = 'https://buscador.handelbay.com'
const lp = "https://lp.handelbay.com/quiero-que-me-llamen"
const blog = url +"/blog"
const cellphone = "1138784340"
const soporte = "https://handelbay.freshdesk.com/support/solutions"
const youtube = "https://www.youtube.com/c/PWAConsultores"
const linkedin = "https://www.linkedin.com/company/markley/"
const indicative = "54" // ARGENTINA
const whatsapp = "https://api.whatsapp.com/send?phone=" + indicative + cellphone
const name_country = "Argentina"

export default {
  url,
  sign_up,
  sign_in,
  search,
  lp,
  blog,
  cellphone,
  soporte,
  youtube,
  linkedin,
  indicative,
  whatsapp,
  name_country
}
</script>
