<template>
  <section id="because-handelbay" class="home-not-background">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-12 col-xs-12 col-md-offset-1 text-left">
          <div class="title" itemscope :itemtype="global.url">
            <h2 class="title-2" itemprop="HandelBay en tu día a día">¿Por qué
              <br>Markley HandelBay?</h2>
            <span class="handelbay-active"></span>
          </div>
          <div class="handelbay-text" itemscope :itemtype="global.url">
            <p itemprop="HandelBay es la solución">
              Queremos transformar el futuro de las compras empresariales y estamos convencidos que dependen de 7 pilares fundamentales:
            </p>
          </div>
        </div>
        <div class="col-md-7 col-sm-12 col-xs-12">
          <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-6">
              <div class="image-1">
                <span>1</span>
                <a href="javascript:void(0)">
                  <img src="../assets/images/landing/handelbay/handelbay_porque_1.png" alt="Agilidad">
                  <div class="handelbay-effect-hover" itemscope :itemtype="global.url" style="display: none;">
                    <div class="because-handelbay_text">
                      <h3 class="title" itemprop="futuro">Sin Contratos - Sin Permanencia</h3>
                      <p>
                        Podés dejar de usarlo sin costo cuando quieras.
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
              <div class="col-md-4 col-sm-4 col-xs-6">
                <div class="image-1">
                  <span>2</span>
                  <a href="javascript:void(0)">
                    <img src="../assets/images/landing/handelbay/handelbay_porque_2.png" alt="Agilidad">
                    <div class="handelbay-effect-hover" itemscope itemtype="https://handelbay.com/" style="display: none;">
                      <div class="because-handelbay_text">
                        <h3 class="title" itemprop="transformar">Flujo de Aprobaciones</h3>
                        <p>
                          Flujo de aprobaciones a nivel compras o usuarios.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-6">
                <div class="image-1">
                  <a href="javascript:void(0)">
                    <span>3</span>
                    <img src="../assets/images/landing/handelbay/handelbay_porque_3.png" alt="Agilidad">
                    <div class="handelbay-effect-hover" itemscope itemtype="https://handelbay.com/" style="display: none;">
                      <div class="because-handelbay_text">
                        <h3 class="title" itemprop="empresariales">Reportes - Indicadores a un click</h3>
                        <p>
                          Muy fácil para obtener reportes e indicadores de gestión.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
      <div class="container" style="margin-top:50px">
      <div class="row">
        <div class="col-md-10 col-sm-12 col-xs-12 col-md-offset-1 text-left">
          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-6">
              <div class="image-1">
                <span>4</span>
                <a href="javascript:void(0)">
                  <img src="../assets/images/landing/handelbay/handelbay_porque_4.png" alt="Agilidad">
                  <div class="handelbay-effect-hover" itemscope :itemtype="global.url" style="display: none;">
                    <div class="because-handelbay_text">
                      <h3 class="title" itemprop="futuro">Proceso Abastecimiento Completo</h3>
                      <p>
                        Control de todo el proceso de compra.
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
              <div class="col-md-3 col-sm-3 col-xs-6">
                <div class="image-1">
                  <span>5</span>
                  <a href="javascript:void(0)">
                    <img src="../assets/images/landing/handelbay/handelbay_porque_5.png" alt="Agilidad">
                    <div class="handelbay-effect-hover" itemscope itemtype="https://handelbay.com/" style="display: none;">
                      <div class="because-handelbay_text">
                        <h3 class="title" itemprop="transformar">Más Proveedores y Clientes</h3>
                        <p>
                          Incrementá tu base de datos de proveedores o clientes.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-6">
                <div class="image-1">
                  <a href="javascript:void(0)">
                    <span>6</span>
                    <img src="../assets/images/landing/handelbay/handelbay_porque_6.png" alt="Agilidad">
                    <div class="handelbay-effect-hover" itemscope itemtype="https://handelbay.com/" style="display: none;">
                      <div class="because-handelbay_text">
                        <h3 class="title" itemprop="empresariales">Transparencia y Competencia</h3>
                        <p>
                          Proveedor cotiza en forma independiente. Auditabilidad del sitio.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-6">
                <div class="image-1">
                  <a href="javascript:void(0)">
                    <span>7</span>
                    <img src="../assets/images/landing/handelbay/handelbay_porque_7.png" alt="Agilidad">
                    <div class="handelbay-effect-hover" itemscope itemtype="https://handelbay.com/" style="display: none;">
                      <div class="because-handelbay_text">
                        <h3 class="title" itemprop="empresariales">Experiencia</h3>
                        <p>
                          Con el respaldo de PWA Consultores con sus profesionales de amplia experiencia.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
          </div>
        </div>
      </div>
      <!-- .row -->
    </div>
  </section>
</template>
<script>

import global from "@/components/Global.vue"

export default {
  data(){
    return{
      global: global
    }
  }
}
</script>