<template>
  <div class="benefits_wrap">
    <div class="benefits_items">
      <div class="benefits_item">
        <div class="benefits_header">
          <img src="../assets/images/landing/handelbay/recursos_handel-56.png">
        </div>
        <div class="benefits_body">
          <div class="benefits_body-title">
            <h3 class="title">
              Conseguí cotizaciones
            </h3>
          </div>
          <div class="benefits_body-description">
            <p>Publicá necesidades, recibí cotizaciones, compará y generá órdenes de compra. Hacé el seguimiento post compra y obtené toda la trazabilidad de todos los procesos en línea.</p>
          </div>
        </div>
        <div class="benefits_footer">
          <router-link to="/compradores" class="btn benefits_footer-action">
            Ver más
          </router-link>
        </div>
      </div>
      <div class="benefits_item">
        <div class="benefits_header">
          <img src="../assets/images/landing/handelbay/recursos_handel-54.png">
        </div>
        <div class="benefits_body">
          <div class="benefits_body-title">
            <h3 class="title">
              Aumentá tus ventas
            </h3>
          </div>
          <div class="benefits_body-description">
            <p>Mejorá tu visibilidad 24/7. Recibí invitaciones, cotizá en línea y aumentá tus ventas. Establecé fácilmente relaciones de confianza con nuevos clientes y nuevos mercados.</p>
          </div>
        </div>
        <div class="benefits_footer">
          <router-link to="/compradores" class="btn benefits_footer-action">
            Ver más
          </router-link>
        </div>
      </div>
      <div class="benefits_item">
        <div class="benefits_header">
          <img src="../assets/images/landing/handelbay/recursos_handel-55.png">
        </div>
        <div class="benefits_body">
          <div class="benefits_body-title">
            <h3 class="title">
              Mejorá tus procesos
            </h3>
          </div>
          <div class="benefits_body-description">
            <p>Obtené trazabilidad en tus procesos de compras y ventas en un solo lugar. Sé parte de la red social en expansión de compradores y proveedores verificados más confiable y dinámica del mercado.</p>
          </div>
        </div>
        <div class="benefits_footer">
          <router-link to="/compradores" class="btn benefits_footer-action">
            Ver más
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
}
</script>